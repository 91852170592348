export const AppConstants = {
    ADD_ICON: 'add-circle-outline',
    DEFAULT_VALIDATOR: 'required',
    EDIT_ICON: 'create-outline',
    PRINTER_SCALE: 'printer_scale',
    SETTINGS_ICON: 'cog-outline',
    SKIP_PDF_INFO: 'skip_pdf_info',
    SKIP_PRINTER_INFO: 'skip_printer_info',
    STORAGE_PREFIX: 'orthofuss',
    UNKNOWN_ERROR: 'unknown_error',
    USER_LANGUAGE: 'user_language',
    USER_DATA: 'user_data',
    USER_TOKEN: 'user_token',
    VALIDATORS_KEY: 'validators'
};
