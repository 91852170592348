export const AppPages = {
    AccountPage: '/tabs/settings/account',
    ClientsAdd: '/tabs/clients/add',
    ClientsDetail: '/tabs/clients/${id}',
    ClientsEdit: '/tabs/clients/${id}/edit',
    ClientsList: '/tabs/clients',
    InsolesAdd: '/tabs/settings/insoles/add',
    InsolesEdit: '/tabs/settings/insoles/${id}/edit',
    InsolesList: '/tabs/settings/insoles',
    Overlay: '/tabs/scans/overlay',
    Login: '/login',
    PwCode: '/pw-code',
    LostPw: '/lostpw',
    Register: '/register',
    ScansCapture: '/tabs/scans/${id}/capture',
    ScansClient: '/tabs/scans/client',
    ScansConsent: '/tabs/scans/${id}/consent',
    ScansDetail: '/tabs/scans/${id}',
    ScansDocuments: '/tabs/scans/${id}/documents',
    ScansEdit: '/tabs/scans/${id}/edit',
    ScansImages: '/tabs/scans/${id}/images',
    ScansInsoles: '/tabs/scans/${id}/insoles',
    ScansList: '/tabs/scans',
    SetPw: '/setpw/${code}',
    Settings: '/tabs/settings'
};
